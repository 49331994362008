/* ///////////////////////////////////////////////////////////////// */

.company-page {
  padding-left: 8.33%;
  padding-right: 8.33%;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  grid-column-gap: 24px;
  padding-bottom: 48px;
}

.company-page .left-column {
  grid-column-start: 1;
  grid-column-end: 9;
}

.company-page .right-column {
  grid-column-start: 9;
  grid-column-end: 13;
  padding-top: 48px;
  display: flex;
  flex-direction: column;
}

.company-page .img-container .fill {
  flex-grow: 1;
  height: 100%;
  background: black;
  margin-right: 16px;
  border-radius: 4px;
}

.company-page .img-container {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 4px;
  overflow: hidden;
}

.company-page .section-title {
  font-size: 24px;
  font-weight: 700;
  margin-top: 32px;
}

.company-page .section {
  display: grid;
  grid-template-columns: repeat(8, minmax(0, 1fr));
  grid-column-gap: 24px;
}

.company-page .paragraph {
  font-size: 14px;
  font-weight: 100;
  line-height: 24px;
}

.company-page .image-row {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-column-gap: 24px;
}

@media screen and (max-width: 480px) {
  .company-page .left-column {
    grid-column-start: 1;
    grid-column-end: 13;
  }

  .company-page .image-row {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: 24px;
  }
}
