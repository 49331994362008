.achievements-page {
  padding-left: 8.33%;
  padding-right: 8.33%;
  padding-top: 48px;
  padding-bottom: 48px;
}

.achievements-page .page-title {
  font-size: 24px;
  font-weight: 800;
}

.achievements-page .page-desc {
  margin-top: 8px;
  padding-bottom: 32px;
  border-bottom: solid 1px whitesmoke;
  font-size: 14px;
  line-height: 24px;
}

.achievements-page .achievements {
  margin-top: 32px;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-column-gap: 48px;
}

.achievements-page .achievement {
  margin-bottom: 48px;
}

.achievements-page .achievement .head {
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
}

.achievements-page .achievement .head .image {
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: whitesmoke;
  border-radius: 8px;
  margin-left: auto;
}

.achievements-page .achievement .head .image img {
  width: 100%;
}

.achievements-page .achievement .head .text {
}

.achievements-page .achievement .head .text .primary {
  font-weight: 800;
  /* font-size: 16px; */
}

.achievements-page .achievement .head .text .secondary {
  font-size: 12px;
  color: grey;
}

.achievements-page .achievement .desc {
  /* font-weight: 100; */
  margin-bottom: 16px;
  font-size: 12px;
  line-height: 20px;
}

.achievements-page .achievement .person {
  color: grey;
  font-size: 12px;
}

.achievements-page .achievement .person .name {
  font-weight: 600;
  color: black;
}

.achievements-page .achievement .person .organization {
}

.achievements-page .achievement .person .designation {
}

@media screen and (max-width: 480px) {
  .achievements-page .achievements {
    margin-top: 32px;
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-column-gap: 0;
  }
}
