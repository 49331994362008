.bulk-order-card {
  box-shadow: 0px 4px 8px 0px rgba(96, 97, 112, 0.1);
  margin-top: 24px;
  border: solid 1px whitesmoke;
  border-radius: 8px;
}

.bulk-order-card__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: solid 1px whitesmoke;
  padding: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-weight: 600;
  font-family: "Rajdhani", sans-serif;
  color: #f60;
  letter-spacing: 0.2px;
  font-size: 16px;
}

.bulk-order-card__table {
}

.bulk-order-card__table__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  background: whitesmoke;
  font-size: 10px;
}

.bulk-order-card__table__header__cell {
  text-align: center;
  flex-shrink: 0;
}

.bulk-order-card__table__header__cell:first-child {
  /* text-align: left; */
}

.bulk-order-card__table__header__cell:last-child {
  text-align: right;
}

.bulk-order-card__table__body {
  /* padding: 16px; */
}

.bulk-order-card__table__body__row {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: solid 1px whitesmoke;
  height: 32px;
  padding-left: 16px;
  padding-right: 16px;
  cursor: pointer;
}

.bulk-order-card__table__body__row__cell {
  flex-shrink: 0;
  text-align: center;
}

.bulk-order-card__table__body__row__cell.selected {
  font-weight: 700;
}

.bulk-order-card__table__body__row__cell:first-child {
  /* text-align: left; */
}

.bulk-order-card__table__body__row__cell:last-child {
  text-align: right;
}

.bulk-order-card__table__footer {
  text-align: center;
  text-decoration: underline;
  font-size: 12px;
  padding: 8px;
}

.service-plan-card {
  box-shadow: 0px 4px 8px 0px rgba(96, 97, 112, 0.1);
  margin-top: 32px;
  border: solid 1px whitesmoke;
  border-radius: 8px;
  padding: 16px;
  /* padding-top: 8px; */
  /* padding-bottom: 12px; */
  display: flex;
  flex-direction: row;
}

.service-plan-card__logo img {
  height: 96px;
}

.service-plan-card__primary {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 700;
  font-family: "Rajdhani", sans-serif;
  /* color: #f60; */
  letter-spacing: 0.2px;
  font-size: 12px;
  padding-left: 14px;
}

.service-plan-card__primary__icon {
  margin-right: 4px;
  margin-left: 8px;
}

.service-plan-card__secondary {
  font-size: 12px;
  padding-left: 14px;
}

.service-plan-card__link {
  color: #f60;
  font-size: 12px;
  margin-top: 4px;
  text-decoration: underline;
  margin-left: 14px;
}

.delivery-availability {
  background: whitesmoke;
  border-radius: 8px;
  margin-top: 24px;
  padding: 16px;
  box-shadow: 0px 4px 8px 0px rgba(96, 97, 112, 0.1);
  border: solid 1px whitesmoke;
}

.delivery-availability__title {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 600;
  font-family: "Rajdhani", sans-serif;
  /* color: #f60; */
  letter-spacing: 0.2px;
  /* font-size: 16px; */
}

.delivery-availability__form {
  margin-top: 16px;
  padding-bottom: 16px;
  border-bottom: solid 1px #dedede;
}

.delivery-availability__form form {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.delivery-availability__form input {
  height: 32px;
  flex-grow: 1;
  border: none;
  padding-left: 12px;
  border-radius: 8px;
  /* display: block; */
}

.delivery-availability__form button {
  background: #f09e60;
  border: none;
  height: 32px;
  border-radius: 8px;
  color: white;
  font-weight: 600;
  font-family: "Rajdhani", sans-serif;
  /* color: #f60; */
  letter-spacing: 0.2px;
  width: 32px;
  margin-left: 16px;
}

.delivery-availability__result {
  margin-top: 16px;
  font-weight: 700;
  font-size: 12px;
}

.delivery-availability__result .link {
  color: #f60;
}

.review {
}
.review__item {
  /* padding-top: 24px; */
  margin-bottom: 24px;
  border-bottom: solid 1px whitesmoke;
  padding-bottom: 24px;
}

.review__item:first-child {
  margin-top: 24px;
}

.review__item:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.review__item__title {
  display: flex;

  flex-direction: row;
  align-items: center;
}
.review__item__title__stars {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 8px;
}
.review__item__title__stars__star {
  display: flex;
  align-items: center;
  justify-content: center;
}
.review__item__title__text {
  font-weight: 700;
}
.review__item__content {
  margin-top: 8px;
}
.review__item__reviewer {
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.review__item__reviewer__img {
  background: whitesmoke;
  height: 24px;
  width: 24px;
  border-radius: 100%;
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.review__item__reviewer__name {
  font-size: 12px;
}

.review__item__timestamp {
  font-size: 12px;
  color: grey;
  margin-left: 8px;
  /* margin-top: 8px; */
}

.badges-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 0px;
  margin-bottom: 24px;
  padding-top: 32px;
  border-bottom: solid 1px whitesmoke;
  /* padding-bottom: 32px; */
}

.badges-container__item {
  width: 33%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.badge {
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.badge__icon {
  background: #fef9f686;
  height: 88px;
  width: 88px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 8px 0px rgba(96, 97, 112, 0.1);
}

.badge__description {
  background: white;
  border: solid 1px whitesmoke;
  border-radius: 8px;
  font-size: 10px;
  /* line-height: 14px; */
  width: 120px;
  text-align: center;
  font-weight: 700;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -10px;
  font-weight: 600;
  font-family: "Rajdhani", sans-serif;
  padding-left: 8px;
  padding-right: 8px;
  box-shadow: 0px 4px 8px 0px rgba(96, 97, 112, 0.1);
}

.badge__description.underlined {
  text-decoration: underline;
  text-decoration-style: dotted;
  text-decoration-color: #f60;
  cursor: pointer;
}

.cart-options__free-shipping {
  background: rgb(235, 255, 244);
  color: #00cc66;
  border-radius: 4px;
  margin-top: 16px;
  font-size: 12px;
  font-weight: 700;
  padding-left: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.product-comparison-container {
  margin-top: 64px;
  margin-bottom: 48px;
  height: 680px;
  position: relative;
  overflow: hidden;
}

.product-comparison-container .bg-image-wrapper {
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
  /* position: relative; */
  position: absolute;
  height: 680px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
}

.product-comparison-container .bg-image {
  width: 100%;
  /* transition: all 0.2s ease-out; */
  will-change: transform;
  /* transform: scale(1.1); */
}

.product-comparison-container__overlay {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    2deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 4%,
    rgba(255, 255, 255, 0.5) 26%,
    rgba(255, 255, 255, 0.1) 39%,
    rgba(255, 255, 255, 0.1) 64%,
    rgba(255, 255, 255, 1) 92%,
    rgba(255, 255, 255, 1) 100%
  );
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  box-sizing: border-box;
  padding-left: 8.33%;
  padding-right: 8.33%;
  padding-top: 40px;
  padding-bottom: 40px;
  display: grid;
  grid-template-columns: 36% 64%;
  grid-column-gap: 40px;
  grid-template-rows: 100%;
  /* background: rgb(255, 255, 255);
  background: linear-gradient(
    3deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.500437675070028) 33%,
    rgba(255, 255, 255, 0.7609418767507002) 59%,
    rgba(255, 255, 255, 1) 100%
  ); */
}

.product-comparison-container__overlay__additional-resources {
  box-shadow: 0px 12px 16px 0px rgba(96, 97, 112, 0.1);
  background: rgba(255, 255, 255, 0.8);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow: hidden;
  border: solid 1px whitesmoke;
}

.product-comparison-container__overlay__additional-resources__title {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* margin-bottom: 8px; */
  /* color: #666; */
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 4px;
  padding-right: 4px;
}

.product-comparison-container__overlay__additional-resources__title__icon {
  height: 24px;
  /* margin-left: auto; */
  margin-right: 8px;
  margin-left: 8px;
}

.product-comparison-container__overlay__additional-resources__title__text {
  font-weight: 800;
  font-size: 16px;
  color: var(#666);
  font-family: "Rajdhani", sans-serif;
}

.product-comparison-container__overlay__additional-resources__list {
  flex-grow: 1;
  background: whitesmoke;
  padding: 16px;
  overflow: auto;
}

.product-comparison-container__overlay__additional-resources__list__item {
  /* height: 240px; */
  box-shadow: 0px 4px 8px 0px rgba(96, 97, 112, 0.1);
  background: white;
  border-radius: 8px;
  margin-bottom: 16px;
  padding: 8px;
}

.product-comparison-container__overlay__additional-resources__list__item__img {
  background: whitesmoke;
  height: 176px;
}

.product-comparison-container__overlay__additional-resources__list__item__details {
  display: flex;
  flex-direction: row;
}

.product-comparison-container__overlay__additional-resources__list__item__details__description {
  font-size: 12px;
  margin-top: 8px;
  padding-right: 16px;
}

.product-comparison-container__overlay__additional-resources__list__item__details__description__primary {
  font-weight: 700;
}
.product-comparison-container__overlay__additional-resources__list__item__details__description__secondary {
}

.product-comparison-container__overlay__additional-resources__list__item__details__options {
  display: flex;
  flex-direction: row;
  margin-left: auto;
}

.product-comparison-container__overlay__additional-resources__list__item__details__options__option {
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.product-comparison-container__overlay__additional-resources__list__item__details__options__option
  .icon {
  fill: #666;
}

.product-comparison-container__overlay__additional-resources__list__item__details__options__option:hover
  .icon {
  fill: #f60;
}

.product-comparison-container__overlay .highlights .video-highlights {
  /* height: 320px; */
  box-shadow: 0px 4px 8px 0px rgba(96, 97, 112, 0.1);
  background: rgba(255, 255, 255, 0.8);
  border-radius: 8px;
  /* padding: 16px; */
}

.product-comparison-container__overlay .highlights .video-highlights__footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* border-top: solid 1px whitesmoke; */
  /* margin-top: 8px; */
  /* padding: 8px; */
  padding-top: 4px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 10px;
  font-weight: 700;
}

.product-comparison-container__overlay
  .highlights
  .video-highlights__footer
  .prev {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.product-comparison-container__overlay
  .highlights
  .video-highlights__footer
  .next {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.product-comparison-container__overlay
  .highlights
  .video-highlights__footer
  .video-count {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-comparison-container__overlay .highlights .highlight-items {
  /* height: 260px; */
  /* margin-top: 40px; */
  display: flex;
  flex-wrap: wrap;
  padding-top: 48px;
  /* display: grid;
  grid-template-columns: 33% 33% 33%;
  grid-template-rows: 160px 160px; */
}

.product-comparison-container__overlay .table {
  box-shadow: 0px 4px 8px 0px rgba(96, 97, 112, 0.1);
  background: rgba(255, 255, 255, 1);
  border-radius: 8px;
  overflow: hidden;
}

@media screen and (max-width: 480px) {
  .badges-container__item {
    width: 50%;
  }

  .service-plan-card__logo img {
    /* height: 64px; */
  }

  .service-plan-card__primary__icon {
    margin-right: 12px;
    margin-left: 16px;
  }

  .service-plan-card__primary {
    font-size: 12px;
    text-align: center;
  }

  .service-plan-card__content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .product-comparison-container {
    margin-top: 0px;
    /* height: auto; */
    height: auto;
    grid-template-columns: 100%;
    position: relative;
    overflow: hidden;
  }

  .product-comparison-container .bg-image {
    height: 120%;
    width: auto;
  }

  .product-comparison-container__overlay {
    padding-left: 0px;
    padding-right: 0px;
    /* padding-top: 40px; */
    /* padding-bottom: 40px; */
    display: block;
  }

  .product-comparison-container .table {
    height: 640px;
    width: 100%;
    margin-top: 32px;
  }

  .App
    .content
    .product-page
    .highlight-section
    .meta
    .price
    .primary-price.usd {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .App
    .content
    .product-page
    .highlight-section
    .meta
    .price
    .primary-price.usd
    .price-metas {
    padding-top: 0;
  }

  .product-comparison-container__overlay__additional-resources {
    border-top: solid 1px whitesmoke;
    margin-top: 16px;
  }

  .product-comparison-container__overlay__additional-resources__title {
    align-items: center;
    justify-content: center;
    padding-right: 16px;
  }
}
