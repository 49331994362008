.home-page {
  background-image: url(bg_category_thumb.jpeg);
  background-position: center;
  background-repeat: repeat;
  background-size: cover;
  background-attachment: fixed;
}

.home-page .hero {
  height: 500px;
  background: white;
  position: relative;
}

.home-page .hero .video {
  overflow: hidden;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
}

.home-page .hero .video video {
  width: 100%;
}

.home-page .hero .overlay {
  height: 100%;
  width: 100%;
  background: #f60;
  opacity: 0.1;
  position: absolute;
}

.home-page .hero .text {
  position: absolute;
  height: 100%;
  width: 100%;
  padding-left: 8.33%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.home-page .hero .text .line {
  font-size: 54px;
  font-family: "Rajdhani", sans-serif;
  font-weight: 600;
  color: white;
}

.home-page .hero .text .line .highlight {
  color: #f60;
}

.home-page .hero .text .subtext {
  color: white;
  font-size: 16px;
  font-weight: 600;
  margin-top: 16px;
  border-top: solid 2px #f60;
  display: inline;
  padding-top: 24px;
  padding-right: 72px;
}

.home-page .hero .cta {
  height: 48px;
  color: white;
  background: #f60;
  display: inline-block;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 160px;
  font-weight: 800;
  margin-top: 24px;
  margin-bottom: 48px;
}

.home-page .popular {
  background: white;
  padding-left: 8.33%;
  padding-right: 8.33%;
  padding-top: 48px;
  padding-bottom: 48px;
}

.home-page .popular .head {
  display: flex;
  flex-direction: row;
}

.home-page .section-title {
  font-family: "Rajdhani", sans-serif;
  font-weight: 700;
  font-size: 24px;
}

.home-page .popular .head .tabs {
  margin-left: auto;
  display: flex;
  flex-direction: row;
}

.home-page .popular .head .tabs .tab {
  margin-left: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Rajdhani", sans-serif;
  font-weight: 700;
}

.home-page .popular .head .tabs .tab.active {
  color: white;
  background: #f60;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 8px;
}

.home-page .popular .products-list {
  display: grid;
  grid-template-columns: 30% 70%;
  grid-column-gap: 32px;
  margin-top: 32px;
}

.home-page .popular .most-popular {
  border: solid 1px whitesmoke;
  box-shadow: 0px 4px 8px 0px rgba(96, 97, 112, 0.1);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
}

.home-page .popular .most-popular .image {
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-page .popular .most-popular .image img {
  width: 100%;
}

.home-page .popular .most-popular .title {
  font-weight: 800;
  font-size: 16px;
  text-align: center;
}

.home-page .popular .most-popular .desc {
  margin-top: 16px;
  padding: 16px;
  text-align: center;
}

.home-page .popular .most-popular .cta {
  margin-left: 16px;
  margin-right: 16px;
  background: #f60;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  color: white;
  font-family: "Rajdhani", sans-serif;
  font-weight: 700;
  border-radius: 8px;
  margin-top: 16px;
}

.home-page .popular .most-popular .options {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: auto;
  border-top: solid 1px whitesmoke;
  height: 40px;
}

.home-page .popular .most-popular .options .option {
  font-size: 12px;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.home-page .popular .most-popular .options .option:first-child {
  border-right: solid 1px whitesmoke;
}

.home-page .popular .most-popular .options .option .icon {
  height: 12px;
}

.home-page .popular .items {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-template-rows: repeat(2, 300px);
}

.home-page .popular-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.2s ease-out;
  cursor: pointer;
  margin-bottom: 16px;
  text-decoration: none;
  color: black;
  /* border: solid 1px white; */
}

.home-page .popular-item .image {
  height: 75%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-page .popular-item .image img {
  width: 100%;
}

.home-page .popular-item:hover {
  /* border-color: whitesmoke; */
  box-shadow: 0px 4px 8px 0px rgba(96, 97, 112, 0.1);
}

.home-page .popular-item .title {
  font-weight: 700;
  font-size: 16px;
}

.home-page .popular-item .options {
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-top: solid 1px whitesmoke;
  width: 100%;
  height: 32px;
  margin-top: auto;
  padding-left: 16px;
  padding-right: 16px;
  opacity: 0;
  transition: all 0.2s ease-out;
}

.home-page .popular-item .options .option {
  font-size: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.home-page .popular-item .options .option .icon {
  height: 12px;
}

.home-page .popular-item:hover .options {
  opacity: 1;
}

.home-page .services {
  color: white;
  padding: 48px;
  padding-bottom: 64px;
  padding-left: 8.33%;
  padding-right: 8.33%;
}

.home-page .services .section-title {
  text-align: center;
  margin-bottom: 48px;
}

.home-page .services .items {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-column-gap: 32px;
}

.home-page .services .items .service {
}

.home-page .services .items .service .image {
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-page .services .items .service .image img {
  width: 100%;
}

.home-page .services .items .service .desc {
  height: 40px;
  background: #f60;
  font-family: "Rajdhani", sans-serif;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-page .brands {
  background: white;
  padding: 48px;
  padding-left: 8.33%;
  padding-right: 8.33%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home-page .brands .section-title {
  text-align: center;
  display: inline-block;
  border-bottom: solid 2px #f60;
  padding-bottom: 8px;
}

.home-page .brands .items {
  width: 100%;
  margin-top: 32px;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.home-page .brands .brand {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;
}

.home-page .brands .brand .image {
  height: 48px;
}

.home-page .brands .brand .image img {
  height: 100%;
}

.home-page .brands .view-all {
  height: 32px;
  background: #f60;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding-left: 16px;
  padding-right: 16px;
  font-family: "Rajdhani", sans-serif;
  font-weight: 700;
  color: white;
  margin-top: 32px;
}

.home-page .categories {
  padding: 48px;
  padding-left: 8.33%;
  padding-right: 8.33%;
  padding-bottom: 64px;
}

.home-page .categories .section-title {
  text-align: center;
  color: white;
}

.home-page .categories .items {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-column-gap: 24px;
  margin-top: 48px;
}

.home-page .categories .items .main-category {
  background: white;
  height: 200px;
  /* width: 50%; */
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  padding: 16px;
}

.home-page .categories .items .main-category .image {
  height: 100%;
}

.home-page .categories .items .main-category .image img {
  height: 100%;
}

.home-page .categories .items .main-category .desc {
  font-family: "Rajdhani", sans-serif;
  font-weight: 700;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 24px;
}

.home-page .categories .items .sub-categories {
  flex-grow: 1;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-column-gap: 24px;
  grid-template-rows: repeat(2, minmax(0, 1fr));
  grid-row-gap: 24px;
}

.home-page .categories .items .category {
  background: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 8px;
  padding: 16px;
}

.home-page .categories .items .category .image {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-page .categories .items .category .image img {
  width: 60%;
}

.home-page .categories .items .category .desc {
  font-family: "Rajdhani", sans-serif;
  font-weight: 700;
  font-size: 16px;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-left: 16px;
  padding-right: 16px;
}

@media screen and (max-width: 480px) {
  .home-page .hero .video video {
    object-fit: initial;
    object-position: -250px;
    height: 500px;
    width: auto;
    margin-right: 500px;
  }

  .home-page .hero .text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 0;
  }

  .home-page .hero .text .line {
    font-size: 40px;
    text-align: center;
  }

  .home-page .hero .text .subtext {
    font-size: 16px;
    font-weight: 600;
    margin-top: 16px;
    border-top: solid 2px #f60;
    display: inline;
    padding-top: 24px;
    padding-right: 0;
    text-align: center;
  }

  .home-page .popular .head {
    flex-direction: column;
  }

  .home-page .popular .head .section-title {
    text-align: center;
  }

  .home-page .popular .head .tabs {
    margin-top: 16px;
    margin-left: 0;
    flex-shrink: 0;
    height: 32px;
  }

  .home-page .popular .products-list {
    grid-template-columns: 100%;
  }

  .home-page .popular .items {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-rows: repeat(2, 240px);
  }

  .home-page .services .items {
    grid-template-columns: 100%;
    grid-column-gap: 0;
  }

  .home-page .services .items .service {
    margin-bottom: 32px;
  }

  .home-page .brands .items {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: 32px;
  }

  .home-page .categories .items {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-column-gap: 24px;
    margin-top: 48px;
  }

  .home-page .categories .items .main-category {
    background: white;
    height: 240px;
    /* width: 50%; */
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 32px;
  }

  .home-page .categories .items .main-category .image {
    width: 100%;
  }

  .home-page .categories .items .main-category .image img {
    width: 100%;
  }

  .home-page .categories .items .category .image img {
    width: 100%;
  }
}
