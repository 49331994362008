.cart-small {
  background: white;
  z-index: 5;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 8px;
  box-shadow: 0px 20px 32px 0px rgba(96, 97, 112, 0.1);
  margin-top: 4px;
  border: solid 1px whitesmoke;
  border-radius: 8px;
  font-size: 12px;
  width: 400px;
}

.cart-small__item {
  display: flex;
  flex-direction: row;
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: solid 1px whitesmoke;
}

.cart-small__item__image {
  height: 64px;
  width: 64px;
  background: whitesmoke;
  border-radius: 8px;
  margin-right: 16px;
}

.cart-small__item__content {
  flex-grow: 1;
}

.cart-small__item__content__description {
}

.cart-small__item__content__description__primary {
  font-weight: 700;
  margin-bottom: 2px;
}

.cart-small__item__content__description__secondary {
  font-size: 12px;
  color: grey;
}

.cart-small__item__content__summary {
  margin-top: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.cart-small__item__content__summary__price {
  font-weight: 400;
}

.cart-small__item__content__summary__quantity {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.cart-small__item__content__summary__quantity .minus {
  height: 20px;
  width: 20px;
  border-radius: 2px;
  background: whitesmoke;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cart-small__item__content__summary__quantity .plus {
  height: 20px;
  width: 20px;
  border-radius: 2px;
  background: whitesmoke;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cart-small__item__content__summary__quantity .number {
  font-weight: 400;
  margin-left: 8px;
  margin-right: 8px;
  font-size: 12px;
}

.cart-small__totals {
  padding-top: 16px;
  padding-bottom: 16px;
}

.cart-small__totals__row {
  display: flex;
  flex-direction: row;
  margin-bottom: 4px;
}

.cart-small__totals__row.grand-total {
  margin-top: 16px;
  border-top: solid 1px whitesmoke;
  padding-top: 16px;
}

.cart-small__totals__row__label {
  color: grey;
}

.cart-small__totals__row__value {
  margin-left: auto;
  font-weight: 700;
}

.cart-small__totals__row__value.negative {
  color: #f60;
}

.cart-small__options__option {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  font-weight: 700;
  font-family: "Rajdhani", sans-serif;
  text-decoration: none;
  color: black;
  letter-spacing: 0.2px;
  font-size: 14px;
}

.cart-small__options__option.button {
  background: #f60;
  color: white;
  margin-bottom: 8px;
}

@media screen and (max-width: 480px) {
  .cart-small {
    width: 100%;
  }
}
