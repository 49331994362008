.App {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
}

.App.modal-open {
  overflow-y: hidden;
}

.App .header {
}

.app-modal {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  transition: all 0.2s ease-out;
  opacity: 0;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.app-modal.open {
  opacity: 1;
  pointer-events: all;
}

.app-modal .modal-card {
  background: white;
  height: 480px;
  width: 480px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.App .header .secondary-header {
  height: 32px;
  background: #eee;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
  font-size: 12px;
  padding-left: 8.33%;
  padding-right: 8.33%;
}

.App .header .secondary-header .link {
}

.App .header .secondary-header .seperator {
  color: #666;
  margin-right: 16px;
  margin-left: 16px;
}

.App .header .primary-header {
  height: 120px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
  padding-left: 8.33%;
  padding-right: 8.33%;
}

.App .header .primary-header .logo {
}

.App .header .primary-header .options {
  margin-left: auto;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
}

.App .header .primary-header .options .search {
  border: solid 1px #dedede;
  border-radius: 8px;
  height: 40px;
  width: 400px;
  margin-right: 32px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  overflow: hidden;
}

.App .header .primary-header .options .search input {
  flex-grow: 1;
  border: none;
  outline: none;
  padding-left: 12px;
}

.App .header .primary-header .options .search .submit-button {
  height: 32px;
  width: 32px;
  border-radius: 6px;
  margin: 3px;
  background: #f60;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  /* margin-left: auto; */
}

.App .header .primary-header .options .option-button {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  border: solid 1px #dedede;
  border-radius: 8px;
  height: 40px;
  margin-right: 32px;
  padding-left: 8px;
  padding-right: 8px;
  cursor: pointer;
  transition: all 0.2s ease-out;
}

.App .header .primary-header .options .option-button:hover {
  border-color: #f60;
}

.App .header .primary-header .options .option-button .icon {
  padding-left: 8px;
  padding-right: 8px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
}

.App .header .primary-header .options .option-button .text {
  font-size: 12px;
  padding-right: 8px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

.App .header .primary-header .options .option-button .text .primary {
  font-weight: 600;
}

.App .header .primary-header .options .option-button .text .secondary {
  font-size: 10px;
}

.App .header .primary-header .options .primary-button {
  height: 40px;
  padding-left: 16px;
  padding-right: 16px;
}

.App .header .nav {
  height: 48px;
  background: #0d0d0d;
  padding-left: 8.33%;
  padding-right: 8.33%;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.App .header .nav .nav-item {
  color: white;
  font-family: "Rajdhani", sans-serif;
  height: 100%;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  margin-right: 40px;
  font-weight: 600;
  font-size: 16px;
  text-decoration: none;
}

.App .header .nav .nav-item.active {
  color: #f60;
}

.App .header .nav .nav-menu {
  background: white;
  box-shadow: 0px 4px 8px 0px rgba(96, 97, 112, 0.1);
}

.App .header .nav .nav-menu .nav-menu-item {
  text-decoration: none;
  padding: 24px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 12px;
  color: grey;
  display: block;
}

.App .content {
}

.App .content .product-page {
}

.App .content .breadcrumbs {
  height: 36px;
  background: #f5f5f5;
  padding-left: 8.33%;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
}

.App .content .breadcrumbs .crumb {
  font-size: 12px;
}

.App .content .breadcrumbs .seperator {
  margin-right: 12px;
  margin-left: 12px;
}

.App .content .product-page .highlight-section {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  padding-top: 48px;
  padding-left: 8.33%;
  padding-right: 8.33%;
}

.App .content .product-page .highlight-section .media {
  width: 60%;
  position: relative;
  /* display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  will-change: transform; */
}

.App .content .product-page .highlight-section .media .offset-scroll-container {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  /* position: absolute; */
  /* top: 0; */
  /* width: 100%; */
}

.App .content .product-page .highlight-section .media .gallery {
  flex-shrink: 0;
  /* height: 600px; */
  overflow: auto;
}

.App .content .product-page .highlight-section .media .gallery .gallery-item {
  height: 64px;
  width: 64px;
  background: whitesmoke;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.App
  .content
  .product-page
  .highlight-section
  .media
  .featured-image-container {
  padding-left: 40px;
  padding-right: 40px;
  flex-grow: 1;
}

.App
  .content
  .product-page
  .highlight-section
  .media
  .featured-image-container
  .featured-image {
  background: whitesmoke;
  min-height: 400px;
}

.App .content .product-page .highlight-section .meta {
  width: 40%;
}

.App .content .product-page .highlight-section .meta .primary {
  margin-bottom: 12px;
}

.App .content .product-page .highlight-section .meta .primary .category {
  font-weight: 600;
  font-size: 14px;
  color: #666;
  margin-bottom: 8px;
  letter-spacing: 0.2px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.App
  .content
  .product-page
  .highlight-section
  .meta
  .primary
  .category
  .stock-availability {
  background: #45b145;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  height: 24px;
  /* margin-left: auto; */
  border-radius: 8px;
  padding-left: 12px;
  padding-right: 12px;
}

.App
  .content
  .product-page
  .highlight-section
  .meta
  .primary
  .category
  .header-share-btn {
  margin-left: auto;
  margin-right: 8px;
}

.App .content .product-page .highlight-section .meta .primary .primary-text {
  font-size: 32px;
  font-weight: 700;
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
}

.App .content .product-page .highlight-section .meta .variants {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 8px;
}

.App .content .product-page .highlight-section .meta .variants__item {
  border: solid 1px #dedede;
  border-radius: 4px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 16px;
  padding-right: 16px;
  margin-right: 16px;
  margin-bottom: 16px;
  color: grey;
  cursor: pointer;
  font-size: 12px;
}

.App .content .product-page .highlight-section .meta .variants__item.selected {
  background: #ff8533;
  border-color: #ff8533;
  color: white;
  font-weight: 700;
}

.App .content .product-page .highlight-section .meta .variants__item.disabled {
  background: whitesmoke;
  cursor: default;
}

.App .content .product-page .highlight-section .meta .secondary {
  margin-bottom: 16px;
}

.App
  .content
  .product-page
  .highlight-section
  .meta
  .secondary
  .secondary-text {
  font-size: 14px;
  /* margin-bottom: 8px; */
  /* font-weight: 100; */
  /* color: #666; */
  letter-spacing: 0.2px;
  /* color: #828282; */
}

.App
  .content
  .product-page
  .highlight-section
  .meta
  .secondary
  .secondary-text
  .green {
  color: #45b145;
  font-weight: 800;
}

.App
  .content
  .product-page
  .highlight-section
  .meta
  .secondary
  .secondary-text
  .bold {
  font-weight: 800;
}

.App .content .product-page .highlight-section .meta .blurb {
  /* color: #666; */
  border-top: solid 1px whitesmoke;
  /* border-bottom: solid 1px whitesmoke; */
  padding-top: 8px;
  /* padding-bottom: 24px; */
  margin-bottom: 24px;
  line-height: 24px;
}

.App .content .product-page .highlight-section .meta .blurb .bold {
  font-weight: 600;
}

.App .content .product-page .highlight-section .meta .blurb .in-the-box {
  margin-top: 8px;
  margin: 0;
  padding-left: 18px;
}

.App .content .product-page .highlight-section .meta .price {
  /* margin-bottom: 16px; */
  flex-grow: 1;
}

.App .content .product-page .highlight-section .meta .price .primary-price {
  font-size: 32px;
  font-weight: 700;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  /* align-items: center; */
  /* align-items: flex-end; */
}

.App .content .product-page .highlight-section .meta .price .secondary-price {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.App
  .content
  .product-page
  .highlight-section
  .meta
  .price
  .secondary-price
  .text {
  color: #ff8533;
  text-decoration: line-through;
}

.App
  .content
  .product-page
  .highlight-section
  .meta
  .price
  .primary-price
  .price-metas {
  padding-top: 8px;
}

.App
  .content
  .product-page
  .highlight-section
  .meta
  .price
  .primary-price
  .subtext {
  font-size: 9px;
  /* margin-top: -4px; */
  margin-left: 10px;
  /* margin-top: 1px; */
  margin-bottom: 7px;
}

.App
  .content
  .product-page
  .highlight-section
  .meta
  .price
  .primary-price
  .subtext
  .link {
  border-bottom: solid 1px #f60;
  cursor: pointer;
}

.App
  .content
  .product-page
  .highlight-section
  .meta
  .price
  .primary-price
  .primary-price__discount {
  background: #00cc66;
  margin-left: 12px;
  font-size: 10px;
  color: whitesmoke;
  font-weight: 700;
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 2px;
  height: 14px;
  /* margin-top: 16px; */
}

.App
  .content
  .product-page
  .highlight-section
  .meta
  .price
  .secondary-price
  .primary-price__discount {
  background: #00cc66;
  /* margin-left: 12px; */
  margin-right: 12px;
  font-size: 10px;
  color: whitesmoke;
  font-weight: 700;
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 2px;
  height: 14px;
  /* margin-top: 16px; */
}

.App .content .product-page .highlight-section .meta .quantity {
  height: 48px;
  border-radius: 8px;
  border: solid 1px #666;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.App .content .product-page .highlight-section .meta .quantity .picker {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
  flex-grow: 1;
  height: 36px;
}

.App .content .product-page .highlight-section .meta .quantity .unit {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 9px;
  letter-spacing: 1px;
  height: 12px;
  background: whitesmoke;
  font-weight: 700;
  border-top: solid 1px #666;
  color: #666;
}

.App .content .product-page .highlight-section .meta .quantity .minus {
  height: 32px;
  width: 32px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  cursor: pointer;
}

.App .content .product-page .highlight-section .meta .quantity .plus {
  height: 32px;
  width: 32px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.App .content .product-page .highlight-section .meta .quantity .number {
  font-weight: 800;
  font-size: 16px;
  height: 32px;
  width: 32px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
}

.App .content .product-page .highlight-section .cart-options {
}

.App .content .product-page .highlight-section .cart-options .discount {
  border: dashed 2px #00cc66;
  border-radius: 8px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 16px;
  padding-right: 16px;
  color: #00cc66;
  font-weight: 700;
  margin-right: 16px;
}

.App .content .product-page .highlight-section .cart-options__buttons {
  display: grid;
  grid-template-columns: 40% 55%;
  grid-column-gap: 24px;
}

.App
  .content
  .product-page
  .highlight-section
  .cart-options
  .price-quantity-container {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
  margin-bottom: 24px;
}

.App .content .product-page .highlight-section .meta .ancillary-info {
  /* border: solid 1px #666; */
  margin-top: 24px;
  border-radius: 8px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.App
  .content
  .product-page
  .highlight-section
  .meta
  .ancillary-info
  .ancillary-info-list {
  flex-grow: 1;
}

.App
  .content
  .product-page
  .highlight-section
  .meta
  .ancillary-info
  .ancillary-info-item {
  padding-top: 8px;
  padding-bottom: 8px;
  /* border-bottom: solid 1px #666; */
  /* padding-left: 16px; */
  padding-right: 16px;
  font-size: 12px;
  font-weight: 600;
  color: #666;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
}

.App
  .content
  .product-page
  .highlight-section
  .meta
  .ancillary-info
  .ancillary-info-item:last-child {
  border-bottom: none;
}

.App
  .content
  .product-page
  .highlight-section
  .meta
  .ancillary-info
  .ancillary-info-item
  .icon {
  width: 32px;
}

.App .content .product-page .highlight-section .meta .brand {
  /* background: whitesmoke; */
  /* width: 30%; */
  /* display: -webkit-flex;
  display: -ms-flex;
  display: flex; */
  /* -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  margin-left: auto; */
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.App .content .product-page .highlight-section .meta .brand .brand-image {
  /* display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: flex-end; */
  margin-left: -4px;
}

.App .content .product-page .highlight-section .meta .brand .brand-image img {
  height: 24px;
}

.App .content .product-page .highlight-section .meta .brand .brand-name {
  color: #666;
  font-size: 10px;
  margin-bottom: 6px;
  letter-spacing: 0.5px;
}

.App .content .product-page .highlight-section .meta .secondary-options {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-gap: 24px;
  /* margin-top: 24px; */
  padding-top: 24px;
  /* padding-bottom: 24px; */
  /* margin-bottom: 24px; */
  /* border-top: solid 1px whitesmoke; */
  /* border-bottom: solid 1px whitesmoke; */
}

.App .content .product-page .highlight-section .meta .secondary-option-item {
  height: 72px;
  border: solid 1px #f60;
  /* background: whitesmoke; */
  border-radius: 8px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-align-items: center;
  justify-content: center;
  align-items: center;
  font-family: "Rajdhani", sans-serif;
  font-size: 12px;
  letter-spacing: 0.2px;
}
.App
  .content
  .product-page
  .highlight-section
  .meta
  .secondary-option-item
  .icon {
  margin-bottom: 4px;
}

.App .content .product-page .highlight-section .meta .tabbed-section {
  /* margin-top: 32px; */
  margin-bottom: 24px;
}

.App .content .product-page .highlight-section .meta .tabbed-section .tabs {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  border-bottom: solid 1px #d8d8d8;
}

.App
  .content
  .product-page
  .highlight-section
  .meta
  .tabbed-section
  .tabs
  .tab {
  margin-right: 16px;
  padding-bottom: 12px;
  padding-right: 4px;
  color: #666;
}

.App
  .content
  .product-page
  .highlight-section
  .meta
  .tabbed-section
  .tabs
  .tab.active {
  border-bottom: solid 2px #f60;
  color: black;
  font-weight: 600;
}

.App
  .content
  .product-page
  .highlight-section
  .meta
  .tabbed-section
  .tabbed-section-content {
  border-bottom: solid 1px whitesmoke;
  padding-bottom: 8px;
  /* min-height: 500px; */
}

.App
  .content
  .product-page
  .highlight-section
  .meta
  .tabbed-section
  .tabbed-section-content
  .li {
  margin-top: 16px;
  margin-left: 18px;
}

.App
  .content
  .product-page
  .highlight-section
  .meta
  .tabbed-section
  .tabbed-section-content
  .highlights-tab {
  min-height: 200px;
}

.App
  .content
  .product-page
  .highlight-section
  .meta
  .tabbed-section
  .tabbed-section-content
  .highlights-tab
  .highlight-section-title {
  font-weight: 700;
  margin-top: 24px;
}

.App
  .content
  .product-page
  .highlight-section
  .meta
  .tabbed-section
  .tabbed-section-content
  .content-list {
  padding-left: 0;
}

.App .content .product-page .info-section {
}

.App .footer {
  height: 496px;
  background: black;
}

.App .tag {
}

.App .primary-button {
  height: 48px;
  background: #f60;
  border-radius: 8px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-family: "Rajdhani", sans-serif;
  color: white;
  letter-spacing: 0.2px;
  font-size: 16px;
}

.App .secondary-button {
  height: 48px;
  border: solid 1px #f60;
  border-radius: 8px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-family: "Rajdhani", sans-serif;
  color: #f60;
  letter-spacing: 0.2px;
  font-size: 16px;
}

.App .tertiary-button {
}

.App .spec-list {
  margin-top: 8px;
}

.App .spec-list .spec-list-item {
  height: 40px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
}

.App .spec-list .spec-list-item .key {
  font-weight: 600;
}

.App .spec-list .spec-list-item .value {
  flex: 1;
  text-align: right;
}

.App .similar-products {
  background: whitesmoke;
  /* margin-top: 32px; */
  padding-left: 8.33%;
  padding-right: 8.33%;
  padding-top: 32px;
  padding-bottom: 48px;
}

.App .similar-products .sp-title {
  font-size: 24px;
  font-family: "Rajdhani", sans-serif;
  font-weight: 600;
}

.App .similar-products .sp-list {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
  overflow-x: auto;
  margin-top: 16px;
}

.App .similar-products .sp-list-item {
  background: white;
  height: 250px;
  width: 25%;
  margin-right: 16px;
  flex-shrink: 0;
  border-radius: 8px;
  padding: 16px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.App .similar-products .sp-list-item .image {
  background: whitesmoke;
  flex: 1;
  margin-bottom: 8px;
}

.App .similar-products .sp-list-item .details {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
}

.App .similar-products .sp-list-item .details .text {
  flex-grow: 1;
}

.App .similar-products .sp-list-item .details .brand {
  height: 32px;
  width: 48px;
  background: whitesmoke;
  flex-shrink: 0;
}

.App .similar-products .sp-list-item .name {
}

.App .similar-products .sp-list-item .price {
  font-weight: 800;
}

.App .enquiry-form {
  margin-top: 24px;
}

.App .enquiry-form .row {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-bottom: 16px;
}

.App .enquiry-form .form-section {
  margin-bottom: 24px;
}

.App .enquiry-form .form-section .title {
  font-weight: 600;
  margin-bottom: 8px;
}

.App .enquiry-form .form-group {
  width: 100%;
}

.App .enquiry-form .form-group .label {
  font-family: "Rajdhani", sans-serif;
  color: #666;
  /* font-size: 12px; */
  /* font-weight: 100; */
  margin-bottom: 4px;
}

.App .enquiry-form .form-group input {
  height: 36px;
  border: solid 1px #dedede;
  width: 100%;
  border-radius: 8px;
  padding-left: 12px;
}
.App .enquiry-form .form-group input:focus {
  border: solid 1px #f60;
  outline: none;
}

.App .enquiry-form .form-group textarea {
  height: 120px;
  border: solid 1px #dedede;
  width: 100%;
  border-radius: 8px;
  padding: 12px;
  padding-top: 8px;
  font-family: "Open Sans", sans-serif;
}

.App .enquiry-form .form-group textarea:focus {
  border: solid 1px #f60;
  outline: none;
}

.App .enquiry-form .row.two-column .form-group {
  padding-right: 16px;
}

.App .enquiry-form .row.two-column .form-group:last-child {
  padding-right: 0;
}

@media screen and (max-width: 480px) {
  .App .content .breadcrumbs {
    padding-left: 16px;
  }

  .App .content .product-page .highlight-section {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-top: 16px;
    padding-left: 16px;
    padding-right: 16px;
  }

  .App .content .product-page .highlight-section .media {
    width: 100%;
  }

  .App
    .content
    .product-page
    .highlight-section
    .media
    .offset-scroll-container {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .App .content .product-page .highlight-section .media .gallery {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    width: 100%;
    margin-top: 24px;
  }

  .App .content .product-page .highlight-section .media .gallery .gallery-item {
    margin-right: 16px;
  }

  .App
    .content
    .product-page
    .highlight-section
    .media
    .featured-image-container {
    width: 100%;
    padding: 0;
  }

  .App
    .content
    .product-page
    .highlight-section
    .media
    .featured-image-container
    .featured-image {
    background: whitesmoke;
    min-height: 200px;
  }

  .App .content .product-page .highlight-section .meta {
    width: 100%;
  }

  .App .content .product-page .highlight-section .meta .secondary-option-item {
    font-size: 10px;
  }

  .App .similar-products .sp-list-item {
    width: 75%;
  }

  .App .header .primary-header {
    height: 60px;
    padding-left: 16px;
    padding-right: 16px;
  }

  .App .header .primary-header .logo img {
    height: 40px;
  }

  .App .header .primary-header .options .option-button {
    margin-right: 0;
    margin-left: 16px;
    height: 40px;
    width: 40px;
  }

  .App .header .primary-header .options .option-button .icon {
    padding: 0;
    height: 40px;
    width: 40px;
  }

  .App .header .secondary-header {
    padding-left: 16px;
    padding-right: 16px;
  }

  .App .header .nav {
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .App .header .nav .nav-menu-button {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .App .content .product-page .highlight-section .cart-options__buttons {
    display: grid;
    grid-template-columns: 100%;
    grid-column-gap: 0px;
  }

  .App
    .content
    .product-page
    .highlight-section
    .cart-options__buttons
    .secondary-button {
    margin-bottom: 16px;
  }

  .App .content .product-page .highlight-section .cart-options .discount {
    display: none;
  }

  .App .content .product-page .highlight-section .meta .secondary-options {
    grid-column-gap: 12px;
  }
}
