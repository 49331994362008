.branches-page {
  padding-left: 8.33%;
  padding-right: 8.33%;
  padding-top: 48px;
  padding-bottom: 48px;
}

.branches-page .page-title {
  font-size: 24px;
  font-weight: 800;
}

.branches-page .page-desc {
  margin-top: 8px;
  padding-bottom: 32px;
  border-bottom: solid 1px whitesmoke;
  font-size: 14px;
  line-height: 24px;
}
.branches-page .section {
  padding-top: 32px;
  border-top: solid 1px whitesmoke;
}

.branches-page .section .section-title {
  font-size: 16px;
  font-weight: 800;
  color: #f60;
  margin-bottom: 16px;
}

.branches-page .section .items {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-column-gap: 32px;
}

.branches-page .section .items .branch {
  margin-bottom: 32px;
}

.branches-page .section .items .branch .city {
  font-weight: 800;
  margin-bottom: 8px;
}

.branches-page .section .items .branch .address {
  margin-bottom: 8px;
  font-size: 12px;
}

.branches-page .section .items .branch .contact {
  font-size: 12px;
}

.branches-page .section .items .branch .contact .name {
  /* font-weight: 800; */
}

.branches-page .section .items .branch .contact .contact-item {
  display: flex;
  flex-direction: row;
  margin-bottom: 4px;
}

.branches-page .section .items .branch .contact .contact-item .icon {
  margin-right: 8px;
}

@media screen and (max-width: 480px) {
  .branches-page .section .items {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
