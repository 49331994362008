.associates-page {
  padding-left: 8.33%;
  padding-right: 8.33%;
  padding-top: 48px;
  padding-bottom: 48px;
}

.associates-page .page-title {
  font-size: 24px;
  font-weight: 800;
}

.associates-page .page-desc {
  margin-top: 8px;
  padding-bottom: 32px;
  border-bottom: solid 1px whitesmoke;
  font-size: 14px;
  line-height: 24px;
}

.associates-page .items {
  margin-top: 32px;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-column-gap: 32px;
}

.associates-page .associate {
}

.associates-page .associate .image {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.associates-page .associate .image img {
  height: 100%;
}

.associates-page .associate .primary {
  font-size: 16px;
  margin-top: 16px;
  margin-bottom: 8px;
  font-weight: 800;
}

.associates-page .associate .secondary {
  margin-bottom: 8px;
  font-size: 12px;
  line-height: 20px;
}

.associates-page .associate .link {
  color: #f60;
  font-weight: 600;
}

@media screen and (max-width: 480px) {
  .associates-page .items {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .associates-page .associate {
    margin-bottom: 32px;
  }
  .associates-page .associate .image img {
    width: 100%;
  }
}
