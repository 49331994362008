.careers-page {
  padding-left: 8.33%;
  padding-right: 8.33%;
  padding-top: 48px;
  padding-bottom: 48px;
  display: flex;
  flex-direction: row;
}

.careers-page .page-title {
  font-size: 24px;
  font-weight: 800;
}

.careers-page .page-desc {
  margin-top: 32px;
  font-size: 14px;
  line-height: 24px;
}

.careers-page .page-desc .bold {
  font-weight: 800;
}

.careers-page .left-column {
  width: 50%;
  padding-right: 48px;
}

.careers-page .right-column {
  width: 50%;
}

.careers-page .right-column img {
  width: 100%;
}

@media screen and (max-width: 480px) {
  .careers-page {
    flex-direction: column-reverse;
  }

  .careers-page .right-column {
    width: 100%;
  }

  .careers-page .left-column {
    padding: 0;
    width: 100%;
    margin-top: 32px;
  }
}
