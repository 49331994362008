.cart-page {
  background: whitesmoke;
  padding-left: 8.33%;
  padding-right: 8.33%;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 320px;
  grid-column-gap: 40px;
  padding-top: 40px;
  padding-bottom: 40px;
}

.cart-page__shopping-cart {
  background: white;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 24px;
  box-shadow: 0px 2px 4px 0px rgba(96, 97, 112, 0.1);
}

.cart-page__shopping-cart__title {
  font-weight: 700;
  font-family: "Rajdhani", sans-serif;
  color: black;
  /* letter-spacing: 0.2px; */
  font-size: 16px;
  border-bottom: solid 1px whitesmoke;
  padding-bottom: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.cart-page__shopping-cart__body {
}

.cart-item {
  padding-top: 24px;
  padding-bottom: 24px;
  border-bottom: solid 1px whitesmoke;
  display: flex;
  flex-direction: row;
}

.cart-item__image {
  height: 88px;
  width: 88px;
  background: whitesmoke;
  border-radius: 8px;
  margin-right: 24px;
  flex-shrink: 0;
}

.cart-item__content {
  flex-grow: 1;
  font-size: 12px;
}

.cart-item__content__primary {
  font-weight: 700;
  /* font-size: 16px; */
  margin-bottom: 8px;
}

.cart-item__content__secondary {
  margin-top: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.cart-item__content__secondary .green {
  color: #45b145;
  font-weight: 700;
}

.cart-item__content__options {
  color: grey;
  margin-top: 4px;
}

.cart-item__content__options__option {
  padding-left: 8px;
  padding-right: 8px;
}

.cart-item__content__options__option:first-child {
  padding-left: 0;
}

.cart-item__price {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 78px;
  flex-shrink: 0;
}

.cart-item__price__value {
  /* font-size: 16px; */
  font-weight: 700;
  margin-bottom: 8px;
}

.cart-item__price__quantity {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
  height: 28px;
  border-radius: 8px;
  /* border: solid 1px #666; */
  margin-top: 8px;
  background: whitesmoke;
  box-shadow: 0px 2px 4px 0px rgba(96, 97, 112, 0.1);
}

.cart-item__price__quantity .minus {
  height: 28px;
  width: 28px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  /* font-size: 24px; */
}

.cart-item__price__quantity .plus {
  height: 24px;
  width: 24px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  /* font-size: 24px; */
}

.cart-item__price__quantity .number {
  font-weight: 800;
  font-size: 12px;
  height: 24px;
  width: 24px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
}

.cart-page__shopping-cart__footer {
  /* height: 48px; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  font-size: 16px;
  padding-top: 24px;
  color: grey;
  /* padding-bottom: 24px; */
}

.cart-page__shopping-cart__footer .subtotal {
  min-width: 78px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  margin-left: 16px;
  color: black;
}

.cart-page__order-summary {
  background: white;
  box-shadow: 0px 2px 4px 0px rgba(96, 97, 112, 0.1);
  border-radius: 8px;
  box-sizing: border-box;
  padding: 24px;
  /* font-size: 14px; */
}

.cart-page__order-summary__title {
  font-weight: 700;
  font-family: "Rajdhani", sans-serif;
  color: black;
  /* letter-spacing: 0.2px; */
  font-size: 16px;
  border-bottom: solid 1px whitesmoke;
  padding-bottom: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.cart-page__order-summary__totals {
  padding-top: 16px;
}

.cart-page__order-summary__totals__row {
  margin-top: 8px;
  display: flex;
  flex-direction: row;
}

.cart-page__order-summary__totals__row__label {
}

.cart-page__order-summary__totals__row__value {
  margin-left: auto;
  font-weight: 700;
}

.cart-page__order-summary__totals__row__value.negative {
  color: #f60;
}

.cart-page__order-summary__totals__row.grand-total {
  margin-top: 16px;
  border-top: solid 1px whitesmoke;
  padding-top: 16px;
}

.cart-page__order-summary__checkout-button {
  background: #f60;
  border-radius: 8px;
  height: 40px;
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-family: "Rajdhani", sans-serif;
  text-decoration: none;
  color: white;
  letter-spacing: 0.2px;
}

@media screen and (max-width: 480px) {
  .cart-page {
    background: whitesmoke;
    padding-left: 0;
    padding-right: 0;
    box-sizing: border-box;
    display: block;
    padding-top: 0px;
    /* padding-bottom: 40px; */
    padding-bottom: 0;
  }

  .cart-page__shopping-cart {
    padding: 0;
  }

  .cart-page__shopping-cart__title {
    padding: 16px;
    display: flex;
    align-items: center;
  }

  .cart-page .cart-small {
    margin-top: 0;
    border: none;
    box-shadow: none;
    border-radius: 0px;
  }
}
