.checkout-page {
}

.checkout-page__header {
  height: 120px;
  background: white;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  font-weight: 700;
  font-family: "Rajdhani", sans-serif;
  color: black;
  /* letter-spacing: 0.2px; */
  font-size: 32px;
  padding-left: 8.33%;
  padding-right: 8.33%;
}

.checkout-page__body {
  background: whitesmoke;
  padding-left: 8.33%;
  padding-right: 8.33%;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 400px;
  grid-column-gap: 40px;
  padding-top: 40px;
  padding-bottom: 40px;
}

.checkout-page__section {
  background: white;
  margin-bottom: 40px;
  border-radius: 8px;
  box-shadow: 0px 2px 4px 0px rgba(96, 97, 112, 0.1);
}

.checkout-page__section__title {
  font-weight: 700;
  font-family: "Rajdhani", sans-serif;
  color: black;
  font-size: 16px;
  padding: 16px;
  padding-left: 24px;
  padding-right: 24px;
  border-bottom: solid 1px whitesmoke;
}

.checkout-page__section__title__index {
  color: grey;
  margin-right: 8px;
}

.checkout-page__section__body {
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 36px;
  box-sizing: border-box;
}

.checkout-page__section__body .form-group-section {
  margin-top: 20px;
}

.checkout-page__section__body .form-group {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-column-gap: 32px;
}

.checkout-page__section__body .input-group {
  position: relative;
  width: 100%;
  margin-top: 36px;
}

.checkout-page__section__body .input-group .input-required-label {
  color: red;
}

.checkout-page__section__body .input-group__label {
  font-size: 12px;
  font-weight: 600;
  color: grey;
  background: white;
  position: absolute;
  left: 8px;
  top: -8px;
  padding-left: 4px;
  padding-right: 4px;
}

.checkout-page__section__body .input-group__input {
  border: solid 1px #dfdfdf;
  border-radius: 4px;
  height: 44px;
  outline: none;
  padding-left: 12px;
  width: 100%;
}

.checkout-page__section__body .input-group__input:focus {
  border-color: #f60;
}

.checkout-page__section__body__title {
  font-weight: 700;
}

.checkout-page__section__body__title.link {
  color: grey;
  padding-top: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.checkout-page__section__body__title.link:hover {
  text-decoration: underline;
}

.checkout-page__order-summary {
  background: white;
  box-shadow: 0px 2px 4px 0px rgba(96, 97, 112, 0.1);
  border-radius: 8px;
  overflow: hidden;
}

.checkout-page__order-summary__title {
  font-weight: 700;
  font-family: "Rajdhani", sans-serif;
  color: black;
  font-size: 16px;
  padding: 16px;
  border-bottom: solid 1px whitesmoke;
  /* padding-left: 24px; */
  /* padding-right: 24px; */
}

.checkout-page__order-summary .cart-small {
  margin-top: 0;
  border-radius: 0;
  border: none;
  box-shadow: none;
}

.checkout-page__header__payment-button {
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  font-weight: 700;
  font-family: "Rajdhani", sans-serif;
  text-decoration: none;
  color: white;
  letter-spacing: 0.2px;
  font-size: 16px;
  background: #f60;
  margin-left: auto;
  padding-left: 24px;
  padding-right: 24px;
}

.checkout-page__section__payment-button {
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  font-weight: 700;
  font-family: "Rajdhani", sans-serif;
  text-decoration: none;
  color: white;
  letter-spacing: 0.2px;
  font-size: 16px;
  background: #f60;
  margin-left: auto;
  padding-left: 24px;
  padding-right: 24px;
}

@media screen and (max-width: 480px) {
  .checkout-page__header {
    height: 64px;
    background: white;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    /* justify-content: center; */

    /* letter-spacing: 0.2px; */
    font-size: 24px;
    padding-left: 24px;
    padding-right: 24px;
    border-bottom: solid 1px whitesmoke;
  }

  .checkout-page__body {
    background: whitesmoke;
    padding: 0;
    box-sizing: border-box;
    display: block;
  }

  .checkout-page__section__body .form-group {
    display: block;
  }

  .checkout-page__section {
    margin-bottom: 24px;
    border-radius: 0px;
  }

  .checkout-page__section__options {
    padding: 24px;
    padding-top: 0;
  }

  .checkout-page__section__options__option {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-family: "Rajdhani", sans-serif;
    text-decoration: none;
    color: grey;
    letter-spacing: 0.2px;
    font-size: 16px;
    margin-top: 16px;
  }

  .checkout-page .cart-small__options__option.button {
    background: #f60;
    color: white;
    margin-bottom: 8px;
    height: 48px;
    font-size: 16px;
  }

  .checkout-page__order-summary {
    margin-bottom: 16px;
    padding-left: 8px;
    padding-right: 8px;
  }
}
