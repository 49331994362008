.management-page {
  padding-left: 8.33%;
  padding-right: 8.33%;
  display: flex;
  flex-direction: row;
  padding-top: 48px;
  padding-bottom: 48px;
}

.management-page .left-column {
  width: 32%;

  flex-shrink: 0;
}

.management-page .left-column .highlight {
  padding: 24px;
  box-sizing: border-box;
  background: whitesmoke;
  border-radius: 8px;
}

.management-page .right-column {
  padding-left: 48px;
}

.management-page .page-title {
  font-size: 24px;
  font-weight: 800;
}

.management-page .page-desc {
  margin-top: 16px;
  font-size: 12px;
}

.management-page .management-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.management-page .management-item .image {
  border-radius: 100%;
  overflow: hidden;
}

.management-page .management-item .primary-text {
  font-size: 16px;
  color: #f60;
  font-weight: 800;
  margin-top: 16px;
}

.management-page .management-item .secondary-text {
  margin-top: 4px;
  font-size: 12px;
  font-weight: 800;
  text-align: center;
}

.management-page .management-item .desc {
  text-align: center;
  font-size: 12px;
  margin-top: 8px;
}

.management-page .left-column .highlight .management-item {
  margin-bottom: 32px;
}

.management-page .people {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-column-gap: 24px;
}

.management-page .people .management-item {
  margin-top: 32px;
}

.management-page .people .management-item .image {
  height: 128px;
}

.management-page .people .management-item .image img {
  height: 100%;
}

@media screen and (max-width: 480px) {
  .management-page {
    padding-left: 8.33%;
    padding-right: 8.33%;
    display: flex;
    flex-direction: column;
    padding-top: 48px;
    padding-bottom: 48px;
  }

  .management-page .left-column {
    width: 100%;
  }

  .management-page .right-column {
    padding-left: 0;
  }

  .management-page .people {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: 24px;
  }
}
